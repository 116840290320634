import React, { Component } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { ClydeTextInput, LoadingDots } from '@joinclyde/clyde-react-components';

import { claimStatusSwitcher } from '../../../../Utils/claimHelper';
import SmallButton from '../../../Shared/SmallButton';
import { formatDate } from '../../../../../lib/timeUtils';
import { Currency } from '../../../../Utils/Currency';

class ContractSaleItem extends Component {
  state = {
    toggleOpen: false,
    buttonOpen: false,
    saleSerialNumber: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.openActionButtonId && !this.props.openActionButtonId) {
      this.setState({ buttonOpen: false });
    }
  }

  handleToggle = (e) => {
    e.stopPropagation();
    if (e.type === 'click' || e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (this.state.toggleOpen) {
        e.currentTarget.blur();
        this.setState({ buttonOpen: false });
      }
      this.setState((prevState) => ({ toggleOpen: !prevState.toggleOpen }));
    }
  }

  handleButtonToggle = (e) => {
    e.stopPropagation();
    if (this.state.buttonOpen) {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ buttonOpen: !prevState.buttonOpen }));
    this.props.setOpenActionButton(this.props.id);
  }

  handleGoToClaimDetail = () => {
    const { sale: { claimId }, history } = this.props;
    history.push(`/claims/${claimId}`);
    this.setState({ buttonOpen: false });
  }

  handleShowClaimHistory = () => {
    const { sale, _showDisplayClaimHistory } = this.props;
    _showDisplayClaimHistory('extendedWarranty', sale);
    this.setState({ buttonOpen: false });
  }

  handleFileClaim = () => {
    const {
      sale,
      index,
      manageOEMWarranty,
      _showFileClaim,
    } = this.props;

    const oemWarrantyExpiry = moment(sale.purchaseDate).utc().add(sale.oemWarrantyLength, 'months');
    const oemWarrantyActive = oemWarrantyExpiry.isAfter(moment());
    const useLimited = oemWarrantyActive && sale.lineItemId && manageOEMWarranty;
    sale.warrantyType = useLimited ? 'limitedWarranty' : 'extendedWarranty';

    _showFileClaim(sale.warrantyType, { ...sale, index });
    this.setState({ buttonOpen: false });
  }

  handleUpdateOEM = () => {
    const {
      sale: { saleId, oemWarrantyLength },
      _showUpdateOEM,
    } = this.props;

    _showUpdateOEM(saleId, oemWarrantyLength);
    this.setState({ buttonOpen: false });
  }

  handleCancelContract = (e, canCancel) => {
    const {
      sale: {
        saleId,
        source,
      },
      _showCancelContract,
    } = this.props;
    if (canCancel) {
      _showCancelContract(saleId, source);
      this.setState({ buttonOpen: false });
    }
  }

  handleTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  submitNewSerialNumber = () => {
    const {
      sale: { saleId },
      _saveNewContractSaleSerialNumber,
    } = this.props;
    const { saleSerialNumber } = this.state;
    _saveNewContractSaleSerialNumber(saleId, saleSerialNumber);
  }

  render() {
    const {
      id,
      sale,
      sale: { coverageStartType },
      seriaNumberProcessing,
      manageOEMWarranty,
    } = this.props;

    const { buttonOpen, toggleOpen } = this.state;
    const isActive = this.props.isActive && !(coverageStartType === 'ext' && !manageOEMWarranty);

    const sku = sale.sku ? sale.sku : 'Unknown';
    const productName = sale.productName ? sale.productName : 'Unknown';
    const claimDate = sale.claimDate ? formatDate(sale.claimDate) : 'None';
    const classPrefix = isActive ? 'active' : 'expired';

    const toggleClass = `${classPrefix}-contract-details__toggle--${toggleOpen ? 'open' : 'closed'}`;

    let claimClass = `${classPrefix}-contract-details-claim claim__font--${sale.claimState}`;
    claimClass += toggleOpen ? `${classPrefix}-contract-details-claim--open` : '';

    const oemWarrantyExpiry = moment(sale.purchaseDate).utc().add(sale.oemWarrantyLength, 'months');
    const oemWarrantyActive = oemWarrantyExpiry.isAfter(moment());
    const oemWarrantyActiveText = oemWarrantyActive
      ? `Expires ${formatDate(oemWarrantyExpiry)}`
      : `Expired ${formatDate(oemWarrantyExpiry)}`;

    const expiresText = coverageStartType && coverageStartType.toLowerCase() === 'dop'
      ? `Expires on ${formatDate(sale.expiresDate)}`
      : `Expires ${sale.term} year${sale.term > 1 ? 's' : ''} from end of manufacturer's warranty`;

    const useLimited = oemWarrantyActive && sale.lineItemId && manageOEMWarranty;
    const claimText = useLimited ? 'limited warranty claim' : 'claim';

    // ACTIVE ONLY
    let purchaseSourceText = sale.source;
    let cancelText = 'Cancel this contract';
    let canCancel = true;
    if (sale.claimId) {
      cancelText = 'Cannot cancel contracts with claims';
      canCancel = false;
    }
    switch (sale.source) {
      case 'HI':
        purchaseSourceText = 'hiclyde.com';
        break;
      case 'checkoutWidget':
        purchaseSourceText = 'Warranty Anywhere Widget';
        break;
      default:
        // sources 2020/01/30 API, Clyde, Shopify
        break;
    }

    const showButton = sale.claimId || isActive;

    let inactiveDate;
    let refundAmount = null;

    if (sale.refunded) {
      inactiveDate = `Cancelled on ${formatDate(sale.refundedDate)}`;
      refundAmount = (
        <li>
          Refund amount:{ ' ' }
          <Currency amount={ sale.purchasePrice } />
        </li>
      );
    } else {
      inactiveDate = `Expired on ${formatDate(sale.expiresDate)}`;
    }

    const fileClaimButtonClass = classNames('button-nostyle active-contract-details__menu__button', {
      'active-contract-details__menu__button--disabled': sale.fullyReplaced,
    });

    return (
      <div className={ `${classPrefix}-contract-details` } id={ id }>

        <div
          className={ `${classPrefix}-contract-details__toggle ${toggleClass}` }
          onClick={ this.handleToggle }
          onKeyDown={ this.handleToggle }
          tabIndex='0'
          role='button'
          aria-expanded={ toggleOpen }>
          <div className={ `${classPrefix}-contract-details__toggle__left` }>
            <h6>{ productName }</h6><span>| { sale.term } Year</span>
          </div>
          <div className={ `${classPrefix}-contract-details__toggle__right` }>
            <div>
              <span>Most Recent Claim Status: </span>
              <span className={ claimClass }>{ claimStatusSwitcher(sale.claimState) }</span>
            </div>
            { showButton
              && <SmallButton
                id={ `${id}_action_button` }
                customClass={ toggleOpen ? `${classPrefix}-contract-details-button--open` : `${classPrefix}-contract-details-button` }
                onClick={ this.handleButtonToggle }
                isOpen={ buttonOpen } />
            }
          </div>
        </div>
        {
          buttonOpen
            && <div className={ `${classPrefix}-contract-details__menu` }>
              <ul>
                {
                  sale.claimId
                    && <li>
                      <button
                        className={ `button-nostyle ${classPrefix}-contract-details__menu__button` }
                        onClick={ this.handleShowClaimHistory }>
                        See claim history for this contract
                      </button>
                    </li>
                }
                { isActive
                  && <>
                    <li>
                      <button
                        className={ fileClaimButtonClass }
                        onClick={ this.handleFileClaim }>
                        {
                          sale.fullyReplaced
                            ? `Cannot submit a ${claimText} — Contract fully replaced`
                            : `Submit a ${claimText} on this contract`
                        }
                      </button>
                    </li>
                    <li>
                      <button
                        className={ classNames('button-nostyle active-contract-details__menu__button',
                          { 'active-contract-details__menu__button--disabled': !canCancel }) }
                        onClick={ (e) => this.handleCancelContract(e, canCancel) }>
                        { cancelText }
                      </button>
                    </li>
                  </>
                }
                <li>
                  <button
                    className='button-nostyle active-contract-details__menu__button'
                    onClick={ this.handleUpdateOEM }
                  >
                    Update this contract's OEM
                  </button>
                </li>
              </ul>
            </div>
        }

        <Collapse className={ `${classPrefix}-contract-details__collapse` } isOpen={ toggleOpen }>
          <div className='collapse-padding-wrapper'>
            <div className={ `${classPrefix}-contract-details__collapse__content` }>
              <div className={ `${classPrefix}-contract-details__collapse__content__expires` }>
                <h6>{ isActive ? expiresText : inactiveDate }</h6>
                <ul>
                  { !isActive && refundAmount }
                  <li>Source: { isActive ? purchaseSourceText : sale.source }</li>
                </ul>
                <div>Manuf. Warranty:</div>
                <ul>
                  <li>– { sale.oemWarrantyLength } month(s)</li>
                  <li>– { oemWarrantyActiveText }</li>
                </ul>
              </div>
              <div className={ `${classPrefix}-contract-details__collapse__content__info` }>
                <h6>Contract Info</h6>
                <ul>
                  <li>ID: { sale.hashedSaleId }</li>
                  <li>SKU: { sku }</li>
                </ul>
              </div>
              <div className={ `${classPrefix}-contract-details__collapse__content__sale` }>
                <h6>Contract Sale</h6>
                <ul>
                  <li>DOP: { formatDate(sale.purchaseDate) }</li>
                  <li>
                    Price:{ ' ' }
                    <Currency amount={ sale.purchasePrice } />
                  </li>
                  {
                    isActive
                      && <>
                        <li>
                          Serial Number: { seriaNumberProcessing ? <LoadingDots dotColor='clydeBlack' size='small' /> : (sale.serialNumber || 'N/A') }
                        </li>
                        <li>
                          <ClydeTextInput
                            className={ `${classPrefix}-contract-details__collapse__content__sale__serial-number` }
                            name='saleSerialNumber'
                            onChange={ this.handleTextChange }
                            onSubmit={ this.submitNewSerialNumber }
                            placeholder='Update serial number'
                            maxLength={ 40 }
                          />
                        </li>
                      </>
                  }
                </ul>
              </div>
              <div className={ `${classPrefix}-contract-details__collapse__content__claim` }>
                <h6>Claim Status</h6>
                <ul>
                  <li>{ sale.claimState === 'hiClydeHandoff' ? 'Updated:' : 'Submitted:' } { claimDate }</li>
                  <li className={ `${classPrefix}-contract-details-claim` }>Status: { claimStatusSwitcher(sale.claimState) }</li>
                </ul>
              </div>
            </div>
          </div>
        </Collapse>

      </div>
    );
  }
}

export default ContractSaleItem;
