import { compose } from 'redux';
import partial from 'lodash/fp/partial';
import partialRight from 'lodash/fp/partialRight';
import {
  wsConnecting,
  wsOpen,
  wsClosed,
  wsMessage,
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_SEND,
  WEBSOCKET_OPEN,
  WEBSOCKET_CLOSED,
} from '../actions/websocketActions';

const createMiddleware = () => {
  // Hold a reference to the WebSocket instance in use.
  let websocket;
  let websocketConfig;
  const messageQueue = [];

  // A function to create the WebSocket object and attach the standard callbacks

  const initialize = ({ dispatch }, config) => {
    // Instantiate the websocket.
    websocket = new WebSocket(config.url);
    websocketConfig = config;

    // Function will dispatch actions returned from action creators.
    const dispatchAction = partial(compose, [dispatch]);

    // Setup handlers to be called like this:
    // dispatch(open(event));
    websocket.onopen = dispatchAction(wsOpen);
    websocket.onclose = dispatchAction(wsClosed);
    websocket.onmessage = dispatchAction(wsMessage);

    // An optimistic callback assignment for WebSocket objects that support this
    const onConnecting = dispatchAction(wsConnecting);
    // Add the websocket as the 2nd argument (after the event).
    websocket.onconnecting = partialRight(onConnecting, [websocket]);
  };

  /**
   * Close the WebSocket connection and cleanup
   */
  const close = () => {
    if (websocket) {
      console.warn(`Closing WebSocket connection to ${websocket.url} ...`);
      websocket.close();
      websocket = null;
    }
  };
  /**
   * The primary Redux middleware function.
   * Each of the actions handled are user-dispatched.
   */
  return (store) => (next) => (action) => {
    switch (action.type) {
      // User request to connect
      case WEBSOCKET_CONNECT:
        close();
        initialize(store, action.payload);
        next(action);
        break;

      // User request to disconnect
      case WEBSOCKET_DISCONNECT:
        close();
        next(action);
        break;

      // User request to send a message
      case WEBSOCKET_SEND:
        if (websocket && websocket.readyState === 1) {
          websocket.send(action.payload);
        } else if (websocket && websocket.readyState === 0) {
          messageQueue.push(action.payload);
        } else {
          console.warn('WebSocket is closed, ignoring. Trigger a WEBSOCKET_CONNECT first.');
        }
        next(action);
        break;

      case WEBSOCKET_OPEN:
        websocket.send(JSON.stringify({
          type: 'AUTH',
          payload: {
            source: 'dashboard',
            token: websocketConfig.token,
            platform: websocketConfig.platform,
          },
        }));
        while (messageQueue.length > 0) {
          websocket.send(messageQueue.pop());
        }
        next(action);
        break;

      case WEBSOCKET_CLOSED:
        // adding no refresh paths to handle auth websocket connections in FireFox
        const noRefreshPaths = new Set([
          '/shopify-install',
          '/login-processing',
          '/verify-shopify',
          '/verify-shopify-user',
          '/bigcommerce-install',
          '/login-processing',
          '/skeleton-key',
          '/login',
        ]);

        if (!noRefreshPaths.has(window.location.pathname)) {
          window.location.reload();
        }
        next(action);
        break;
      default:
        next(action);
    }
  };
};

export default createMiddleware();
