import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { wsConnect } from './actions/websocketActions';

import './css/importer.css';

import Connect from './components/Auth/Connect';
import LogIn from './components/Auth/LogIn';
import SkeletonKey from './components/Auth/SkeletonKey';
import VerifyShopifyShop from './components/Auth/VerifyShopifyShop';
import VerifyShopifyUser from './components/Auth/VerifyShopifyUser';
import VerifyBigCommerce from './components/Auth/VerifyBigCommerce';
import ShopifyInstall from './components/Auth/ShopifyInstall';
import BigCommerceInstall from './components/Auth/BigCommerceInstall';
import LoginProcessing from './components/Auth/LoginProcessing';
import Onboarding from './components/Onboarding';

import Home from './components/Home';
import Performance from './components/Performance';
import Sales from './components/Sales';
import Products from './components/Products';
import ContractSettings from './components/Products/ContractSettings';
import Customize from './components/Customize';
import Settings from './components/Settings';
import Customers from './components/Customers';
import ClaimsList from './components/Claims/ClaimsList';
import ClaimDetail from './components/Claims/ClaimDetail';

import IntegrationsOAuthRedirect from './components/Settings/IntegrationsTab/OAuthRedirect/LandingPage';

import ProtectedRoute from './components/Utils/ProtectedRoute';

import Errors from './components/Errors';

class App extends Component {
  UNSAFE_componentWillMount() {
    this.props.wsConnect();
  }

  render() {
    return (
      <main>
        <Switch>
          <Route path='/connect' component={ Connect }/>
          <Route path='/login' component={ LogIn }/>
          <Route path='/verify-shopify' component={ VerifyShopifyShop }/>
          <Route path='/verify-shopify-user' component={ VerifyShopifyUser }/>
          <Route path='/verify-bigcommerce' component={ VerifyBigCommerce }/>
          <Route path='/shopify-install' component={ ShopifyInstall }/>
          <Route path='/bigcommerce-install' component={ BigCommerceInstall }/>
          <Route path='/login-processing' component={ LoginProcessing }/>
          <Route path='/skeleton-key' component={ SkeletonKey }/>
          <Route path='/welcome' component={ Onboarding }/>
          <Home>
            <Route path='/performance' redirect='/products' component={ Performance }/>
            <Route path='/sales' redirect='/products' component={ Sales }/>
            <ProtectedRoute path='/contracts' redirect='/products' component={ ContractSettings }/>
            <Route path='/products' redirect='/products' component={ Products }/>
            <ProtectedRoute path='/customize' redirect='/products' component={ Customize }/>
            <Route path='/customers' redirect='/products' component={ Customers }/>
            <Route exact path='/claims' redirect='/products' component={ ClaimsList }/>
            <Route path='/claims/:id' redirect='/products' component={ ClaimDetail }/>
            <Route path='/settings' redirect='/products' component={ Settings }/>

            <Route path='/oauth/redirect' redirect='/products' component={ IntegrationsOAuthRedirect }/>
          </Home>
        </Switch>
        <Errors/>
      </main>
    );
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => bindActionCreators({ wsConnect }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
