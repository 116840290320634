import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingDots } from '@joinclyde/clyde-react-components';
import { verifyShopifyUserOAuth } from '../../actions/authActions';

class VerifyShopifyUser extends React.Component {
  componentDidMount() {
    const query = this.props.location.search.slice(1);
    this.props._verifyShopifyUserOAuth(qs.parse(query));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shopId !== '' && nextProps.userId !== '') {
      this.props.history.push('/welcome');
    }
  }

  render() {
    return (
      <div className='gradient'>
        <LoadingDots dotColor='clydeBlack' dotSize='large' />
      </div>
    );
  }
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { _verifyShopifyUserOAuth: verifyShopifyUserOAuth },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyShopifyUser));
