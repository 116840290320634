import React, { Component } from 'react';
import validator from 'validator';
import {
  LoadingDots,
  ClydeButton,
  ClydeRadio,
  ClydeTextInput,
  ClydePillSelect,
} from '@joinclyde/clyde-react-components';

import formatOrderDate from '../../Shared/formatOrderDate';
import { ReactComponent as UploadArrow } from '../../../../images/upload-arrow.svg';

class FileClaim extends Component {
  state = {
    fileErrors: '',
    formErrors: [],
    inputKey: new Date(), // Necessary to force file input to rerender on clear
    supportingInfo: [], // This would be a blob-ified file upload
  }

  handleInputChange = (e) => {
    const { _updateFileClaimInput } = this.props;
    const { name, value } = e.target;
    _updateFileClaimInput(name, value);
  }

  handleSelectChange = (value) => {
    this.handleInputChange({
      target: {
        value: value.map((item) => (item.value)),
        name: 'claimIssueTypes',
      },
    });
  };

  handleUsageQuestionChange = (e) => {
    const { _updateFileClaimInput } = this.props;
    const { name, value: stringValue } = e.target;

    let booleanValue;
    switch (stringValue) {
      case 'yes':
        booleanValue = true;
        break;
      case 'no':
        booleanValue = false;
        break;
      default:
        booleanValue = undefined;
        break;
    }

    _updateFileClaimInput(name, booleanValue);
  }

  handleFilesChange = (e) => {
    this.setState({
      fileErrors: '',
      supportingInfo: [],
    });

    const { files } = e.target;
    const errors = [];

    if (files.length > 5) {
      errors.push('A maximum of 5 files can be added.');
    }

    const validTypes = ['png', 'jpg', 'jpeg', 'gif', 'pdf'];
    const size = 5 * 1000 * 1000;
    let fileType;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      fileType = files[i].type ? null : files[i].type.split('/')[1];
      if (!fileType) {
        const splitFileName = fileName.split('.');
        fileType = splitFileName[splitFileName.length - 1];
      }
      if (validTypes.indexOf(fileType.toLowerCase()) === -1) {
        errors.push('Unsupported format. Files must be in the .png, .jpg, .pdf, or .gif format.');
      }
      if ((files[i].size) > size) {
        errors.push('File is too large. Please use a file smaller than 5mb.');
      }
    }

    if (errors.length > 0) {
      e.target.value = null;
      this.setState({ fileErrors: errors[0] });
      return false;
    }

    for (const file of files) {
      this.toBase64(file)
        .then((result) => {
          this.setState((prevState) => ({
            supportingInfo: [
              ...prevState.supportingInfo,
              { name: file.name, type: fileType, data: result },
            ],
          }));
        });
    }
    return true;
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  handleSubmit = (e, today) => {
    e.preventDefault();

    const {
      fileClaim: {
        claimData: {
          claimType,
          claimIssueTypes,
          customerEmail,
          failureDate,
          narrative,
          stillFunctional,
          supportEmail,
          usedAsIntended,
        },
        sale,
        claimType: warrantyType,
        lineItem,
      },
      shopClaimSettings,
      orderId,
      _confirmFileClaim,
    } = this.props;

    const isLimitedWarrantyClaim = warrantyType === 'limitedWarranty';

    const errorFields = [];
    if (validator.isEmpty(customerEmail) || !validator.isEmail(customerEmail)) {
      errorFields.push('customerEmail');
    }

    if (validator.isEmpty(supportEmail) || !validator.isEmail(supportEmail)) {
      errorFields.push('supportEmail');
    }

    if (!claimIssueTypes || !claimIssueTypes.length) {
      errorFields.push('issueType');
    }

    if (!isLimitedWarrantyClaim && (claimType === 'accidentalDamage' || claimType === 'stoppedWorking')) {
      // if claimType == accidentalDamage || stoppedWorking, usage questions must be answered
      if (typeof stillFunctional !== 'boolean') {
        errorFields.push('stillFunctional');
      }
      if (typeof usedAsIntended !== 'boolean') {
        errorFields.push('usedAsIntended');
      }
    }

    if (validator.isEmpty(failureDate)
      || validator.isBefore(failureDate, '2018-06-01')
      || validator.isAfter(failureDate, today)
    ) {
      errorFields.push('failureDate');
    }

    if (validator.isEmpty(narrative) || narrative.length > 1000) {
      errorFields.push('narrative');
    }

    if (errorFields.length > 0) {
      this.setState({ formErrors: errorFields });
      return;
    }

    const formData = {
      contractSaleId: sale.saleId,
      claimType,
      customerEmail,
      failureDate,
      narrative,
      stillFunctional,
      supportEmail,
      usedAsIntended,
      warrantyType,
      claimIssueTypes,
    };

    const contractSaleInfo = {
      contractADH: sale.contractADH,
      contractDOP: sale.purchaseDate,
      contractSaleId: sale.saleId,
      expiresDate: sale.expiresDate,
    };

    if (isLimitedWarrantyClaim) {
      formData.lineItem = { ...lineItem, cartId: orderId };
    }

    _confirmFileClaim(formData, this.state.supportingInfo, shopClaimSettings, contractSaleInfo);
  }

  render() {
    const {
      fileClaim: {
        claimData,
        processing,
        sale,
        submitProcessing,
        claimType: warrantyType,
      },
      customerName,
      issueTypes,
    } = this.props;
    const {
      fileErrors,
      formErrors,
      inputKey,
      supportingInfo,
    } = this.state;

    const date = new Date();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const today = `${date.getFullYear()}-${month}-${day}`;
    const isLimitedWarrantyClaim = warrantyType === 'limitedWarranty';

    const validLabel = 'cop-file-claim__label';
    const invalidLabel = 'cop-file-claim__label cop-file-claim__label--invalid';
    let narrativeCharCount = 'cop-file-claim__narrative-char-count';
    narrativeCharCount += claimData.narrative.length >= 1000 ? ' cop-file-claim__narrative-char-count--max' : '';

    const fileNames = [];
    for (const file of supportingInfo) {
      fileNames.push(file.name);
    }

    const issueTypeOptions = issueTypes.map((issue) => ({
      label: issue.label,
      value: issue,
    }));

    return (
      processing
        ? <div className='cop-modal--loading'>
            <LoadingDots dotColor='clydeBlack' dotSize='large' />
          </div>
        : <React.Fragment>
              <div className='cop-modal-content cop-file-claim__content'>

              <div className='cop-claim'>
                <div className='cop-claim__claim-info'>
                  <div className='cop-claim__claim-info__left'>
                    <span>Customer: { customerName }</span>
                    {
                      !isLimitedWarrantyClaim
                      && <span>Contract ID: { sale.hashedSaleId }</span>
                    }
                  </div>
                  <div className='cop-claim__claim-info__right'>
                    <span>
                      <a
                        onClick={ (e) => e.stopPropagation() }
                        href={ `${window._env_.REACT_APP_HICLYDE_HOST}/terms?pid=${sale.productId}&cid=${sale.contractId}` }
                        target='_blank'
                        rel='noopener noreferrer'>{ sale.sku }</a>
                    </span>
                    {
                      !isLimitedWarrantyClaim
                      && <span>Expires: { formatOrderDate(sale.expiresDate) }</span>
                    }
                  </div>
                </div>
              </div>

              <form className='cop-file-claim__form' onSubmit={ (e) => this.handleSubmit(e, today) }>
                <div className='cop-file-claim__input'>
                  <label
                    htmlFor='cop-file-claim-email'
                    className={ formErrors.indexOf('customerEmail') > -1 ? invalidLabel : validLabel }>
                      Customer Email
                  </label>
                  <ClydeTextInput
                    id='cop-file-claim-customer-email'
                    className='cop-file-claim-text'
                    onChange={ this.handleInputChange }
                    name='customerEmail'
                    value={ claimData.customerEmail }
                  />
                </div>

                <div className='cop-file-claim__input'>
                  <label
                    htmlFor='cop-file-claim-email'
                    className={ formErrors.indexOf('supportEmail') > -1 ? invalidLabel : validLabel }>
                      Retailer Support Email
                  </label>
                  <ClydeTextInput
                    id='cop-file-claim-support-email'
                    className='cop-file-claim-text'
                    onChange={ this.handleInputChange }
                    name='supportEmail'
                    value={ claimData.supportEmail }
                  />
                </div>

                <div className='cop-file-claim__input'>
                  <label
                    htmlFor='cop-file-claim-type'
                    className={ formErrors.indexOf('issueType') > -1 ? invalidLabel : validLabel }
                  >
                    Issue Type
                  </label>
                  <ClydePillSelect
                    placeholder='Select issue types'
                    className='cop-file-claim__dropdown'
                    id='cop-file-claim-type'
                    name='claimType'
                    direction='row'
                    onChange={ this.handleSelectChange }
                    options={ issueTypeOptions }
                    multiple
                  />
                </div>

                {
                  !isLimitedWarrantyClaim
                  && <>
                    { // MARKER: AJC 2020/09/14 eventually there will be more than these two issue types to choose from
                      (claimData.claimType === 'accidentalDamage' || claimData.claimType === 'stoppedWorking')
                        && <React.Fragment>
                          <div className='cop-file-claim__input'>
                            <span className={ formErrors.indexOf('usedAsIntended') > -1 ? invalidLabel : validLabel }>
                              Was the customer using this product as intended?
                            </span>

                            <div className='cop-file-claim__input__inline'>
                              <ClydeRadio
                                checked={ claimData.usedAsIntended === true }
                                id='usedAsIntended_true'
                                name='usedAsIntended'
                                value='yes'
                                onChange={ this.handleUsageQuestionChange } />
                              <label htmlFor='usedAsIntended_true' className='cop-file-claim__radio-label'>Yes</label>
                            </div>

                            <div className='cop-file-claim__input__inline'>
                              <ClydeRadio
                                checked={ claimData.usedAsIntended === false }
                                id='usedAsIntended_false'
                                name='usedAsIntended'
                                value='no'
                                onChange={ this.handleUsageQuestionChange } />
                              <label htmlFor='usedAsIntended_false' className='cop-file-claim__radio-label'>No</label>
                            </div>
                          </div>

                          <div className='cop-file-claim__input'>
                            <span className={ formErrors.indexOf('stillFunctional') > -1 ? invalidLabel : validLabel }>
                              Is the product still fully functional?
                            </span>

                            <div className='cop-file-claim__input__inline'>
                              <ClydeRadio
                                checked={ claimData.stillFunctional === true }
                                id='stillFunctional_true'
                                name='stillFunctional'
                                value='yes'
                                onChange={ this.handleUsageQuestionChange } />
                              <label htmlFor='stillFunctional_true' className='cop-file-claim__radio-label'>Yes</label>
                            </div>

                            <div className='cop-file-claim__input__inline'>
                              <ClydeRadio
                                checked={ claimData.stillFunctional === false }
                                id='stillFunctional_false'
                                name='stillFunctional'
                                value='no'
                                onChange={ this.handleUsageQuestionChange } />
                              <label htmlFor='stillFunctional_false' className='cop-file-claim__radio-label'>No</label>
                            </div>
                          </div>
                        </React.Fragment>
                    }
                  </>
                }

                <div className='cop-file-claim__input'>
                  <label
                    htmlFor='cop-file-claim-dof'
                    className={ formErrors.indexOf('failureDate') > -1 ? invalidLabel : validLabel }>Failure Date:
                  </label>
                  <input
                    type='date'
                    id='cop-file-claim-dof'
                    className='cop-file-claim-date'
                    onChange={ this.handleInputChange }
                    placeholder='MM/DD/YYYY'
                    max={ today }
                    min='2018-06-01'
                    name='failureDate'
                    value={ claimData.failureDate } />
                </div>

                <div className='cop-file-claim__input'>
                  <label
                    htmlFor='cop-file-claim-narrative'
                    className='cop-file-claim__input__label-narrative'>
                      <span className={ formErrors.indexOf('narrative') > -1 ? invalidLabel : validLabel }>
                        Describe the problem:
                      </span>
                      <span className={ narrativeCharCount }>
                        { claimData.narrative.length } / 1000
                      </span>
                  </label>
                  <textarea
                    type='textarea'
                    id='cop-file-claim-narrative'
                    className='cop-file-claim-textarea'
                    onChange={ this.handleInputChange }
                    placeholder='What went wrong?'
                    maxLength='1000'
                    name='narrative'
                    value={ claimData.narrative }>
                  </textarea>
                </div>

                <div className='cop-file-claim__input'>
                  <div className='cop-file-claim__input__upload'>
                    <div className='cop-file-claim-upload'>
                      <input
                        type='file'
                        multiple
                        key={ inputKey }
                        id='supportingInfo'
                        className='cop-file-claim-upload__input'
                        name='supportingInfo'
                        disabled={ submitProcessing }
                        onChange={ this.handleFilesChange } />
                      <UploadArrow className='cop-file-claim-upload__img' />
                      {
                        fileNames.length > 0
                          ? <ul className='cop-file-claim-upload__content cop-file-claim-upload__content--files'>
                              { fileNames.map((file, idx) => <li key={ idx }>{ file }</li>) }
                            </ul>
                          : <span className='cop-file-claim-upload__content cop-file-claim-upload__content--label'>
                              Upload Supporting Info
                            </span>
                      }
                    </div>
                  </div>
                  { fileErrors && <span className='cop-file-claim__input__upload-errors'>{ fileErrors }</span> }
                </div>

                <ClydeButton
                  id='confirm-resend-button'
                  className='cop-modal-actions__confirm cop-file-claim-submit'
                  onClick={ this.handleSubmit }
                  processing={ submitProcessing }
                  successText='Filed!'
                >
                  File a claim
                </ClydeButton>
              </form>

            </div>
          </React.Fragment>
    );
  }
}

export default FileClaim;
