import React, { Component } from 'react';
import Img from 'react-image';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { formatDate } from '../../../../lib/timeUtils';

import ContractSaleItem from './ContractSaleItem';
import { Currency } from '../../../Utils/Currency';

class ContractItem extends Component {
  state = {
    openActionButtonId: '',
    toggleOpen: false,
  };

  handleToggle = (e) => {
    e.stopPropagation();
    if (e.type === 'click' || e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (this.state.toggleOpen) {
        e.currentTarget.blur();
      }
      this.setState((prevState) => ({
        openActionButtonId: '',
        toggleOpen: !prevState.toggleOpen,
      }));
    }
  }

  setOpenActionButton = (itemId) => {
    this.setState({ openActionButtonId: itemId });
  }

  getRefundReasonCopy = (refundReason, province) => {
    switch (refundReason) {
      case 'location':
        return 'State/Country not serviceable.';
      case 'license':
        return `${province} licensing needs updating to purchase contract.`;
      case 'quantity':
        return 'Multiple contracts attached in error to product.';
      case 'missingProduct':
        return 'Contract does not match product(s) in order.';
      default:
        return '';
    }
  }

  render() {
    const {
      contract,
      contract: {
        oemWarrantyLength,
        oemWarrantyExpirationDate,
      },
      shopCurrency,
      history,
      isActive,
      manageOEMWarranty,
      isCanceled,
      province,
    } = this.props;

    const { openActionButtonId, toggleOpen } = this.state;
    const productName = contract.variantName ? `${contract.productName}: ${contract.variantName}` : contract.productName || 'Unknown';
    const productSku = contract.productSku || 'Unknown';
    const termsLinkName = contract.categoryName || 'Clyde Contract Terms & Conditions';
    const classPrefix = isActive ? 'active' : 'expired';
    const today = moment.utc();
    const lastDayOEMEligible = moment.utc(oemWarrantyExpirationDate);
    const isWithinOEMPeriod = lastDayOEMEligible.diff(today, 'days') > 0;
    const hasOemWarranty = oemWarrantyLength > 0 && isWithinOEMPeriod;

    return (
      <div className={ `${classPrefix}-contracts` }>
        <div
          className={ `${classPrefix}-contracts__toggle` }
          onClick={ this.handleToggle }
          onKeyDown={ this.handleToggle }
          tabIndex='0'
          role='button'
          aria-expanded={ toggleOpen }>
          <div className={ `${classPrefix}-contracts__toggle__left ${hasOemWarranty ? 'oem' : ''}` }>
            <Img alt={ productName } src={ [contract.productImage, '/images/default-image.svg'] } />
            <div className={ `${classPrefix}-contracts__toggle__left__content` }>
              <span className={ `${classPrefix}-contracts__toggle__left__name` }>{ productName } ({ contract.contractSales.length })</span>
              { contract.manufacturer && <span className='cop-item-copy cop-item-copy--grey'>{ contract.manufacturer }</span> }
              <span className={ `${classPrefix}-contracts__toggle__left__link` }>
                <a
                  onClick={ (e) => e.stopPropagation() }
                  onKeyDown={ (e) => e.stopPropagation() }
                  href={ `${window._env_.REACT_APP_HICLYDE_HOST}/terms?pid=${contract.productId}&cid=${contract.contractId}` }
                  target='_blank'
                  rel='noopener noreferrer'>{ termsLinkName }</a>
              </span>
            </div>
          </div>
          <div className={ `${classPrefix}-contracts__toggle__right ${hasOemWarranty ? 'oem' : ''}` }>
            <div className={ `${classPrefix}-contracts__toggle__right__content ${hasOemWarranty ? 'oem' : ''}` }>
              <span>SKU: { productSku }</span>
              <span>
              Product Price:{ ' ' }
              { contract.productPurchasePrice != null ? (
                <Currency amount={ contract.productPurchasePrice } />
              ) : (
                'Unknown'
              ) }
            </span>
              { contract.contractSales[0].refunded
                && <span>Auto Refund: { contract.contractSales[0].refundReason ? 'true' : 'false' }</span>
              }
              { contract.contractSales[0].refundReason
                && <span>Refund Reason: {
                  this.getRefundReasonCopy(contract.contractSales[0].refundReason, province)
                }</span>
              }
            </div>
            <div className={ `${classPrefix}-contracts__toggle__right__content ${hasOemWarranty ? 'oem' : ''}` }>
              <span>Purchase Date: { formatDate(contract.productPurchaseDate) }</span>
              { contract.productShipDate && <span>Ship Date: { formatDate(contract.productShipDate) }</span> }
              <span>Delivery Date: { contract.productDeliveryDate ? formatDate(contract.productDeliveryDate) : 'Pending' }</span>
            </div>
            {
              hasOemWarranty && !isCanceled
              && <div className={ `${classPrefix}-contracts__toggle__right__content badge-container ${hasOemWarranty ? 'oem' : ''}` }>
                <span className='limited-warranty-badge-contract'>Under Limited Warranty</span>
                { manageOEMWarranty && <span className='limited-warranty-expiration-contract'>Coverage Expires: { formatDate(oemWarrantyExpirationDate) }</span> }
              </div>
            }
          </div>
        </div>

        <Collapse className={ `${classPrefix}-contracts__collapse` } isOpen={ toggleOpen }>
          <div className='collapse-padding-wrapper'>
            <div className={ `${classPrefix}-contracts__collapse__content` }>
              {
                contract.contractSales.map((sale, idx) => {
                  const id = `${sale.saleId}_acsi_${idx}`;
                  return (
                    <ContractSaleItem
                      key={ id }
                      history={ history }
                      id={ id }
                      index={ idx }
                      sale={ sale }
                      shopCurrency={ shopCurrency }
                      openActionButtonId={ id === openActionButtonId }
                      setOpenActionButton={ this.setOpenActionButton }
                      isActive={ isActive }
                      manageOEMWarranty={ manageOEMWarranty }
                    />
                  );
                })
              }
            </div>
          </div>
        </Collapse>

      </div>
    );
  }
}

export default ContractItem;
