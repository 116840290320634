import React, { Component } from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import { claimStatusSwitcher } from '../../../Utils/claimHelper';
import { formatDate } from '../../../../lib/timeUtils';
import { Currency } from '../../../Utils/Currency';

class ShowClaimHistoryModal extends Component {
  componentDidMount() {
    const {
      displayClaimHistory: { data, warrantyType },
      _getAllClaimsOnSale,
    } = this.props;
    const dataForAction = warrantyType === 'extendedWarranty' ? data.saleId : data;
    _getAllClaimsOnSale(dataForAction, warrantyType);
  }

  goToClaimDetailPage = (claimId) => {
    const { history } = this.props;
    history.push(`/claims/${claimId}`);
  }

  buildClaimHistory = () => {
    const { displayClaimHistory: { claimHistory }} = this.props;
    const claimHistoryComponents = claimHistory.map((claim, idx) => (
        <div className='cop-claim-history__history-list__event' key={ idx }>
          <div className='cop-claim-history__history-list__event__group'>
            <span>{ claim.claimState === 'hiClydeHandoff' ? 'Claim last updated on' : 'Claim submitted on' }</span>
            <span>{ formatDate(claim.submitDate) }</span>
          </div>
          <div className='cop-claim-history__history-list__event__group'>
            <span>Failure date</span>
            <span>{ formatDate(claim.submitDate) }</span>
          </div>
          <div className='cop-claim-history__history-list__event__group'>
            <span>Current status</span>
            <span className={ `claim__font--${claim.claimState}` }>{ claimStatusSwitcher(claim.claimState) }</span>
          </div>
          <div className='cop-claim-history__history-list__event__group'>
            <span>Resolution cost</span>
            <span><Currency amount={ claim.resolutionCost || 0 } /></span>
          </div>
          <button
            className='button-nostyle button-fakelink claims-detail-card__group__info'
            onClick={ () => { this.goToClaimDetailPage(claim.claimId); } }>
            Go to { claim.claimState.includes('resolve') ? 'Resolution' : 'Claim' } Details
          </button>
        </div>
    ));

    return claimHistoryComponents;
  }

  render() {
    const {
      customerName,
      displayClaimHistory: { data, processing },
      _cancelDisplayClaimHistory,
    } = this.props;
    const claimHistory = this.buildClaimHistory();

    return (
      <div className='cop-modal-background' onClick={ _cancelDisplayClaimHistory }>
        <div className='cop-modal-foreground cop-modal-foreground--update-claim' onClick={ (e) => e.stopPropagation() }>

          <div className='cop-modal-header'>
            <button className='button-nostyle cop-modal-header__cancel' onClick={ _cancelDisplayClaimHistory }>
              &#x2715;
            </button>
            <p className='cop-modal-header__explanation'>Keep your customer in the loop</p>
            <h1 className='cop-modal-header__header'>View claim history</h1>
          </div>

          {
            processing
              ? <div className='cop-claim-history cop-claim-history--loading'>
                <LoadingDots dotColor='clydeBlack' dotSize='large' />
              </div>
              : <div className='cop-modal-content cop-claim-history'>
                <div className='cop-claim__claim-info'>
                  <div className='cop-claim__claim-info__left'>
                    <span>Customer: { customerName }</span>
                    {
                      data.hashedSaleId
                      && <span>Contract ID: { data.hashedSaleId }</span>
                    }
                  </div>
                  <div className='cop-claim__claim-info__right'>
                    <span>
                      <a
                        onClick={ (e) => e.stopPropagation() }
                        href={ `${window._env_.REACT_APP_HICLYDE_HOST}/terms?pid=${data.productId}&cid=${data.contractId}` }
                        target='_blank'
                        rel='noopener noreferrer'>{ data.sku }</a>
                    </span>
                    <span>Expires: { formatDate(data.expiresDate) }</span>
                  </div>
                </div>

                <div className='cop-claim-history__history-list'>
                  {
                    claimHistory.length > 0
                      ? claimHistory
                      : <div className='cop-claim-history__history-list__event cop-claim-history__history-list__event--no-history'>
                          <span >No claim history on this contract</span>
                        </div>
                  }
                </div>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ShowClaimHistoryModal;
