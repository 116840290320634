export default {
  ProductLimitExceeded: 'New product limit exceeded.',
  MissingProductField: 'Missing required fields.',
  SKUDuplicate: 'Duplicate SKU.',
  StripeError: 'An error occurred while contacting Stripe.',
  StripeAuthError: 'Stripe couldn\'t connect this account. Try again or contact us for assistance.',
  MicrodepositAmounts: 'Invalid microdeposit amounts. Verify that the amount entered is in cents.',
  StripeBankAccountExists: 'This bank account has already been added to your Clyde account',
  LoginAuthFailed: 'Could not log in with the provided credentials.',
  TokenExpired: 'Your session has expired.',

  UserVerificationFailed: 'Invalid Email/Password.',
  UserDoesNotExist: 'If a matching account was found an email was sent to your inbox to allow you to reset your password.',
  RateLimitReached: 'Too many attempts, try again in a couple minutes.',
  IncorrectPassword: 'Invalid password.',
  FileTooLarge: 'Your file is too large.',
  NoContractsMatched: 'No contracts available for export just yet...',
  NoConcurrentLaunch: 'Your contracts are already launching. Please wait for this process to finish before launching again.',
  NoRejectedLaunch: 'Your shop is currently unable to launch. Please contact us for more assistance.',
  CreateUser: 'There was an error when attempting to create this user. Please contact us for more assistance.',
  DuplicateEmail: 'That email address has already been taken.',
  InvalidInvoiceDates: 'No billing charges were made in the provided date range.',
  InvalidClaimsDates: 'There are no Limited Warranty Claims for the provided claims.',
  UnexpectedError: 'An unexpected error occurred.',
  StrongerPasswordRequired: 'Please use a stronger password.  Try using numbers, capitals and special characters.',

  CSVTooLarge: 'Uploaded CSV exceeds row limit (max 1000).',
  MissingShopifyShopData: 'You must fill out your shop\'s address in Shopify before installing Clyde',
};
