import React, { Component } from 'react';
import classnames from 'classnames';
import { UnmountClosed } from 'react-collapse';
import { Currency } from '../Utils/Currency';

class ContractSale extends Component {
  state = {
    expand: false,
    showActions: false,
  }

  toggleExpand = () => {
    this.setState({
      expand: !this.state.expand,
      showActions: false,
    });
  }

  toggleShowActions = () => {
    this.setState({ showActions: !this.state.showActions });
  }

  openContractUrl = (e) => {
    e.stopPropagation();
    window.open(
      `${window._env_.REACT_APP_HICLYDE_HOST}/terms?pid=${this.props.sale.productId}&cid=${this.props.sale.contractId}`,
      '_blank',
    );
  }

  handleResendEmail = () => {
    this.props.showConfirmResendEmail(this.props.sale.id);
  }

  render() {
    const { sale } = this.props;
    return (
      <div data-test={ `contractRow-${this.props.sale.contractSku}` } className={ classnames('sale-item',
        { 'sale-item--expanded': this.state.expand }) }>
        <div
          onClick={ () => { this.toggleExpand(); } }
          className={ this.state.expand ? 'sale-item__content sale-item__content--expanded row' : 'sale-item__content row' }>
          <div data-test='customerEmail' className='col-6'>
            { this.props.sale.email || this.props.sale.phone || this.props.sale.customerExternalId }
          </div>
          <div data-test='purchaseDate' className='col-2'>
            { this.props.sale.timePaid }
          </div>
          <div data-test='billed' className='col-2'>
            { this.props.sale.billedAt }
          </div>
          <div data-test='refunded' className='col-2'>
            { this.props.sale.refundedAt }
          </div>
        </div>
        <UnmountClosed
          springConfig={ { stiffness: 220, damping: 30 } }
          className={ classnames('col-12 sale-item__details',
            { 'sale-item__details--collapsed': !this.state.expand }) }
          isOpened={ this.state.expand }
        >
          <div data-test='saleDetailsCard' className='sale-details'>
            <div className='row sale-details__header'>
              <div className='col-3'>Customer</div>
              <div className='col-3'>Contract</div>
              <div className='col-3'>Product</div>
              <div className='col-3'>Sale</div>
            </div>
            <div className='row sale-details__content'>
              <div className='col-3 sale-details__content__column'>
                <div>
                  <span>{ this.props.sale.name }</span>
                </div>
                <div>
                  { this.props.sale.email
                    ? <span>{ this.props.sale.email }</span>
                    : null
                  }
                </div>
                <div>
                  { this.props.sale.phone
                    ? <span>{ this.props.sale.phone }</span>
                    : null
                  }
                </div>
                <div>
                  <span>{ this.props.sale.address }</span>
                </div>
                <div>
                  <span>{ `${this.props.sale.city}, ${this.props.sale.province} ${this.props.sale.zip}` }</span>
                </div>
              </div>
              <div className='col-3 sale-details__content__column'>
                <div>
                  <span>SKU: </span>
                  <span data-test='contractSku'><span className='sale-contract-link' onClick={ (e) => { this.openContractUrl(e); } }>{ this.props.sale.contractSku }</span></span>
                </div>
                <div>
                  <span>Contract cost: </span>
                  <Currency data-test='contractCost' amount={ sale.contractCost } />
                </div>
                <div>
                  <span>Your profit: </span>
                  <Currency data-test='yourProfit' amount={ sale.contractMarkup } />
                </div>
                <div>
                  <span>Sum: </span>
                  <Currency data-test='sum' amount={ sale.contractCost + sale.contractMarkup } />
                </div>
              </div>
              <div className='col-3 sale-details__content__column'>
                <div>
                  <span>Name: </span>
                  <span data-test='name'>{ this.props.sale.productName }</span>
                </div>
                <div>
                  <span>SKU: </span>
                  <span data-test='productSku'>{ this.props.sale.productSku || 'n/a' }</span>
                </div>
                <div>
                  <span>Price: </span>
                  <Currency data-test='price' amount={ sale.productPrice } />
                </div>
              </div>
              <div className='col-3 sale-details__content__column'>
                <div>
                  <span>Clyde ID: </span>
                  <span data-test='clydeId'>{ this.props.sale.hashId }</span>
                </div>
                <div>
                  <span>Platform Source: </span>
                  <span data-test='platformSource'>{ this.props.sale.source }</span>
                </div>
                <div>
                  <span>Order ID: </span>
                  <span data-test='orderId'>{ this.props.sale.orderId || 'none' }</span>
                </div>
                <div>
                  <span>Purchase Date: </span>
                  <span data-test='purchaseDate'>{ this.props.sale.timePaid || 'n/a' }</span>
                </div>
                <div>
                  <span>Billed: </span>
                  <span data-test='billed'>{ this.props.sale.billedAt || 'n/a' }</span>
                </div>
                <div>
                  <span>Refunded: </span>
                  <span data-test='refunded'>{ this.props.sale.refundedAt || 'n/a' }</span>
                </div>
              </div>
            </div>
            { this.props.sale.refunded !== true
              ? <div className='row'>
                <div className='col-4'>
                  <div onClick={ this.toggleShowActions } className='actions-menu-expand'>
                    Actions
                    <span className='float-right'>&#8250;</span>
                  </div>
                  <div className={ this.state.showActions ? 'actions-menu actions-menu--expanded' : 'actions-menu' }>
                    <div onClick={ this.handleResendEmail } className='actions-menu__item'>Re-send email</div>
                  </div>
                </div>
              </div>
              : null
            }
          </div>
        </UnmountClosed>
      </div>
    );
  }
}

export default ContractSale;
