import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { LoadingDots, ClydeCheckbox } from '@joinclyde/clyde-react-components';

import PlatformListViewTile from './PlatformListViewTile';
import RegistrableProductSwitch from '../Shared/RegistrableProductSwitch';

class PlatformProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: props.pageNumber,
      allSelected: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.activePage !== nextProps.pageNumber) {
      this.setState({ activePage: nextProps.pageNumber });
    }
    if (this.props.products.every((product) => product.RegistrableProduct)) {
      this.setState({ allSelected: true });
    } else {
      this.setState({ allSelected: false });
    }
  }

  handlePageChange = (pageNumber) => {
    this.props._setPage(pageNumber);
    this.setState({ activePage: pageNumber });
  }

  toggleAllRegistrable = () => {
    const productIds = [];

    for (const product of this.props.products) {
      if (!product.ID || product.ProductID) {
        for (const variant of product.Variants) {
          if (variant.RegistrableProduct === this.state.allSelected) {
            productIds.push(variant.ProductID);
          }
        }
      } else if (product.RegistrableProduct === this.state.allSelected) {
        productIds.push(product.ID);
      }
    }
    if (this.state.allSelected) {
      this.props._deleteRegistrableProduct(productIds);
    } else {
      this.props._createRegistrableProduct(productIds);
    }
  };

  render() {
    const reviewProducts = [];
    const noMatchProducts = [];
    const pendingProducts = [];

    this.props.products.forEach((product, index) => {
      if (product.Reviewed === false) {
        pendingProducts.push({ ...product, Index: index });
        return;
      }
      if (product.Variants.some((variant) => variant.ContractCount > 0)) {
        reviewProducts.push({ ...product, Index: index });
        return;
      }
      noMatchProducts.push({ ...product, Index: index });
    });

    return (
      <div className='page-layout__content'>
      <div className={ `product-pagination-row product-pagination-row--top ${this.props.selectRegistrable
        ? 'product-pagination-row__registrable-products-active'
        : ''}` }>
          <Pagination
            itemClass='page-item'
            linkClass='page-link'
            activePage={ this.state.activePage }
            itemsCountPerPage={ 25 }
            totalItemsCount={ this.props.productCount }
            pageRangeDisplay={ 5 }
            onChange={ this.handlePageChange }
          />
        </div>
        { this.props.selectRegistrable
          ? <div className='col-12'>
            <div className='row products-view__registrable-products-row'>
              <div className='products-view__registrable-products-row-section'>
                <ClydeCheckbox
                  backgroundColor='clydeBlack'
                  id='selectAllProducts'
                  name='selectAllProducts'
                  className='product-view__registrable-products-checkbox'
                  onChange={ this.toggleAllRegistrable }
                  checked={ this.state.allSelected || !this.props.onlyIncludeRegistrableProducts }
                  disabled={ !this.props.onlyIncludeRegistrableProducts }
                />
                Select all on page
              </div>
              <div className='products-view__registrable-products-row-section'>
                <RegistrableProductSwitch
                  _toggleOnlyIncludeRegistrableProducts={
                    () => this.props._toggleOnlyIncludeRegistrableProducts(!this.props.onlyIncludeRegistrableProducts)
                  }
                  onlyIncludeRegistrableProducts={ this.props.onlyIncludeRegistrableProducts }
                  on={ !this.props.onlyIncludeRegistrableProducts }
                  title='Use entire product catalog'
                />
              </div>
            </div>
          </div>
          : null }
        { this.props.loading && !this.props.registrableProductChange
          ? <div className='products-loading-animation-container'>
            <LoadingDots dotColor='clydeBlack' dotSize='large' className='loading-backdrop--clear' />
          </div>
          : <div>
            <div>
              {
                reviewProducts.map((product, index) => (
                    <PlatformListViewTile
                      key={ `review-list-tile-${index}` }
                      product={ product }
                      displayName={ this.props.displayName }
                      shopBranded={ this.props.shopBranded }
                      _startRecordSale={ this.props._startRecordSale }
                      _editProduct={ this.props._editProduct }
                      selectRegistrable = { this.props.selectRegistrable }
                      _createRegistrableProduct = { this.props._createRegistrableProduct }
                      _deleteRegistrableProduct = { this.props._deleteRegistrableProduct }
                      onlyIncludeRegistrableProducts= { this.props.onlyIncludeRegistrableProducts }
                    />
                ))
              }
              {
                pendingProducts.map((product, index) => (
                    <PlatformListViewTile
                      key={ `pending-list-tile-${index}` }
                      product={ product }
                      displayName={ this.props.displayName }
                      shopBranded={ this.props.shopBranded }
                      _startRecordSale={ this.props._startRecordSale }
                      _editProduct={ this.props._editProduct }
                      selectRegistrable = { this.props.selectRegistrable }
                      _createRegistrableProduct = { this.props._createRegistrableProduct }
                      _deleteRegistrableProduct = { this.props._deleteRegistrableProduct }
                      onlyIncludeRegistrableProducts= { this.props.onlyIncludeRegistrableProducts }
                    />
                ))
              }
              {
                noMatchProducts.map((product, index) => (
                    <PlatformListViewTile
                      key={ `no-match-list-tile-${index}` }
                      product={ product }
                      displayName={ this.props.displayName }
                      shopBranded={ this.props.shopBranded }
                      _startRecordSale={ this.props._startRecordSale }
                      _editProduct={ this.props._editProduct }
                      selectRegistrable = { this.props.selectRegistrable }
                      _createRegistrableProduct = { this.props._createRegistrableProduct }
                      _deleteRegistrableProduct = { this.props._deleteRegistrableProduct }
                      onlyIncludeRegistrableProducts= { this.props.onlyIncludeRegistrableProducts }
                    />
                ))
              }
            </div>
          </div>
        }
        <div className='product-pagination-row product-pagination-row--bottom'>
          <Pagination
            itemClass='page-item'
            linkClass='page-link'
            activePage={ this.state.activePage }
            itemsCountPerPage={ 25 }
            totalItemsCount={ this.props.productCount }
            pageRangeDisplay={ 5 }
            onChange={ this.handlePageChange }
          />
        </div>
      </div>
    );
  }
}

export default PlatformProductList;
