import React, { Component } from 'react';
import classnames from 'classnames';
import { SuccessAnimation, ClydeButton, ClydeTextInput } from '@joinclyde/clyde-react-components';

class ResendSaleEmailModal extends Component {
  componentDidMount() {
    const {
      resendSaleEmail: { contact },
      _updateContactResendSaleEmail,
    } = this.props;
    const { isValid, contactType } = this.validateContact(contact);
    _updateContactResendSaleEmail(contact, isValid, contactType);
  }

  handleConfirmResend = () => {
    const { order, resendSaleEmail, _confirmResendSaleEmail } = this.props;

    if (resendSaleEmail.validContact) {
      const useSaleId = order.saleIds[0];
      let useContact = resendSaleEmail.contact;
      if (resendSaleEmail.contactType === 'phone') {
        useContact = useContact.replace(/-/g, '');
      }

      _confirmResendSaleEmail(order.shopId, useSaleId, useContact, resendSaleEmail.contactType);
    }
  }

  handleChangeContact = (e) => {
    const { _updateContactResendSaleEmail } = this.props;
    const contact = e.target.value;
    const { isValid, contactType } = this.validateContact(contact);
    _updateContactResendSaleEmail(contact, isValid, contactType);
  }

  validateContact = (value) => {
    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    const phoneRegex = /^\d{10}$/;
    let isValid = false;
    let contactType;

    if (emailRegex.test(value)) {
      isValid = true;
      contactType = 'email';
    }
    if (phoneRegex.test(value.replace(/-/g, ''))) {
      isValid = true;
      contactType = 'phone';
    }

    return { isValid, contactType };
  }

  render() {
    const { resendSaleEmail, _cancelResendSaleEmail } = this.props;

    return (
      <div className='cop-modal-background' onClick={ _cancelResendSaleEmail }>
        <div className='cop-modal-foreground cop-resend-modal' onClick={ (e) => e.stopPropagation() }>
          {
            resendSaleEmail.step !== 'finish'
              ? <React.Fragment>
                  <div className='cop-modal-header'>
                    <button className='button-nostyle cop-modal-header__cancel' onClick={ _cancelResendSaleEmail }>
                      &#x2715;
                    </button>
                    <p className='cop-modal-header__explanation'>
                      This will send a copy of the contract information from this order to the customer
                    </p>
                    <h1>Re-send contract info</h1>
                  </div>

                  <div className='cop-modal-content cop-resend-modal'>
                    <div className='cop-resend-modal__contact'>
                      <label
                        htmlFor='resend-contact-input'
                        className={ classnames('cop-resend-modal__label',
                          { 'cop-resend-modal__label--invalid': !resendSaleEmail.validContact }) }
                      >
                          Send to (email/phone):
                      </label>
                      <ClydeTextInput
                        id='resend-contact-input'
                        className='cop-resend-modal__input'
                        onChange={ this.handleChangeContact }
                        value={ resendSaleEmail.contact }
                      />
                    </div>
                  </div>

                  <div className='cop-modal-actions'>
                    <ClydeButton
                      id='confirm-resend-button'
                      className='cop-modal-actions__confirm'
                      onClick={ this.handleConfirmResend }
                      processing={ resendSaleEmail.processing }
                      disabled={ !resendSaleEmail.validContact }
                      visible
                      successText='Sent'
                    >
                      Confirm and send
                    </ClydeButton>
                  </div>
                </React.Fragment>
              : <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation text='Sent!' onSuccess={ _cancelResendSaleEmail } />
                </div>
          }
        </div>
      </div>
    );
  }
}

export default ResendSaleEmailModal;
